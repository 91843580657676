<template>
  <section class="mentions">
    <page-title :title="$t('title')" :breadcrumbs="items"></page-title>
    <v-container>
      <v-row>
        <v-col cols="12" md="6" xl="3">
          <v-card elevation="3" height="300" shaped>
            <v-card-title class="text-h5 mb-3 font-weight-bold">
              {{ $t('mentions-1') }}
            </v-card-title>
            <v-card-subtitle>
              <v-img
                src="@/assets/img/accueil/filet_titre.svg"
                width="150"
                height="20"
                contain
              />
            </v-card-subtitle>
            <v-card-text>
              <div>Zone Industrielle Route de Vire</div>
              <div>Condé-sur-Noireau</div>
              <div>14110 CONDE EN NORMANDIE</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" xl="3">
          <v-card elevation="3" height="300" shaped>
            <v-card-title class="text-h5 mb-3 font-weight-bold">
              {{ $t('mentions-2') }}
            </v-card-title>
            <v-card-subtitle>
              <v-img
                src="@/assets/img/accueil/filet_titre.svg"
                width="150"
                height="20"
                contain
              />
            </v-card-subtitle>
            <v-card-text>
              <div>
                <strong>{{ $t('mentions-2-1') }} :</strong>
                {{ $t('mentions-2-1-1') }}
              </div>
              <div><strong>RCS :</strong> 312 733 033 RCS CAEN</div>
              <div>
                <strong>{{ $t('mentions-2-2') }} :</strong> FR76 312733033
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" xl="3">
          <v-card elevation="3" height="300" shaped>
            <v-card-title class="text-h5 mb-3 font-weight-bold">
              {{ $t('mentions-3') }}
            </v-card-title>
            <v-card-subtitle>
              <v-img
                src="@/assets/img/accueil/filet_titre.svg"
                width="150"
                height="20"
                contain
              />
            </v-card-subtitle>
            <v-card-text>
              <div>
                <strong>{{ $t('mentions-3-1') }} :</strong> Nicolas Casas
              </div>
              <div>
                <strong>{{ $t('mentions-3-2') }} : </strong>
              </div>
              <div>Zone Industrielle Route de Vire</div>
              <div>Condé-sur-Noireau</div>
              <div>14110 CONDE EN NORMANDIE</div>
              <div>02.31.59.53.00</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" xl="3">
          <v-card elevation="3" height="300" shaped>
            <v-card-title class="text-h5 mb-3 font-weight-bold">
              {{ $t('mentions-4') }}
            </v-card-title>
            <v-card-subtitle>
              <v-img
                src="@/assets/img/accueil/filet_titre.svg"
                width="150"
                height="20"
                contain
              />
            </v-card-subtitle>
            <v-card-text>
              <div>
                <strong>{{ $t('mentions-4-1') }} :</strong> CORLET IMPRIMEUR
              </div>
              <div>
                <strong>{{ $t('mentions-4-2') }} : </strong>
              </div>
              <div>CORLET IMPRIMEUR</div>
              <div>Zone Industrielle Route de Vire</div>
              <div>Condé-sur-Noireau</div>
              <div>14110 CONDE EN NORMANDIE</div>
              <div>
                <strong>{{ $t('mentions-4-3') }} :</strong> 2 000 000 €
              </div>
              <div>
                <strong>{{ $t('mentions-4-4') }} :</strong> contact@rapidbook.fr
              </div>
              <div>
                <strong>{{ $t('mentions-4-5') }} :</strong> 02.31.59.53.00
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-expansion-panels class="mb-3 border-shaped" elevation="3">
            <v-expansion-panel>
              <v-expansion-panel-header class="text-h5 font-weight-bold">{{
                $t('mentions-5')
              }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-lazy transition="fade-transition">
                  <pdf src="/assets/pdf/CGV - 2021.pdf" />
                </v-lazy>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageTitle from '@/components/info/PageTitle'
import pdf from 'vue-pdf'

export default {
  name: 'Mentions',
  metaInfo: {
    title: 'Rapidbook - Mentions',
    metas: [
      {
        name: 'com.silverpop.brandeddomains',
        content:
          'www.pages06.net,corlet.fr,rapidbook.fr,www.corlet.fr,www.rapidbook.fr',
      },
    ],
    script: [
      {
        src: 'https://www.sc.pages06.net/lp/static/js/iMAWebCookie.js?40625c3e-18bd96a8009-3c9d767ebba0be65b5fa68bf26a6ee93&h=www.pages06.net',
      },
    ],
  },
  components: { PageTitle, pdf },
  data() {
    return {
      items: [
        {
          text: 'Rapidbook',
          disabled: false,
          to: '/',
        },
        {
          text: 'Mentions',
          disabled: true,
          to: '',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.mentions {
  min-height: calc(100vh - 64px);
}
</style>

<i18n lang="json">
{
  "fr": {
    "title": "Mentions Légales",
    "mentions-1": "Adresse du siège social",
    "mentions-2": "Informations Financières",
    "mentions-2-1": "Forme Juridique",
    "mentions-2-1-1": "Société par actions simplifiée",
    "mentions-2-2": "Numéro de TVA intracommunautaire",
    "mentions-3": "Hébergeur & Responsable",
    "mentions-3-1": "Responsable de la publication",
    "mentions-3-2": "Hébergeur du site",
    "mentions-4": "Adresse Établissement",
    "mentions-4-1": "Enseigne",
    "mentions-4-2": "Établissement",
    "mentions-4-3": "Capital social",
    "mentions-4-4": "Adresse mail",
    "mentions-4-5": "Tel standard Corlet Logistic",
    "mentions-5": "Conditions générales de vente"
  },
  "en": {
    "title": "Legal Notices",
    "mentions-1": "Headquarters Address",
    "mentions-2": "Financial Information",
    "mentions-2-1": "Legal Form",
    "mentions-2-1-1": "Simplified joint stock company",
    "mentions-2-2": "Intracommunity VAT Number",
    "mentions-3": "Host & Responsible",
    "mentions-3-1": "Publication Manager",
    "mentions-3-2": "Website Host",
    "mentions-4": "Establishment Address",
    "mentions-4-1": "Business Name",
    "mentions-4-2": "Establishment",
    "mentions-4-3": "Share Capital",
    "mentions-4-4": "Email Address",
    "mentions-4-5": "Corlet Logistic Phone Number",
    "mentions-5": "Terms and Conditions of Sale"
  }
}
</i18n>
