<template>
  <section>
    <v-container>
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" md="10">
              <v-textarea
                v-model="info.message"
                label="Message d'alerte"
                placeholder="Aucun message d'alerte"
                outlined
                counter
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="2">
              <div>
                <v-switch
                  v-model="info.enabled"
                  label="Activé/Désactivé"
                  @change="toggleInfo"
                ></v-switch>
                <v-btn
                  :loading="loading_info"
                  class="border-shaped yellow--bg"
                  depressed
                  large
                  block
                  @click="updateInfo"
                  >Enregistrer</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="my-6"></v-divider>
      <v-row>
        <v-col>
          <v-card-title>
            <div>Derniers produits non validés</div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search_products"
              class="border-shaped"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
              outlined
            ></v-text-field>
          </v-card-title>
          <v-data-table
            class="border-shaped elevation-1"
            :loading="loading_last_products"
            :search="search_products"
            dense
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Uid', value: 'uid' },
              { text: 'ISBN', value: 'isbn' },
              { text: 'Référence', value: 'reference' },
              { text: 'Titre', value: 'title' },
              { text: 'Auteur', value: 'author' },
              { text: 'Nombre de pages', value: 'page_count' },
              { text: 'Epaisseur', value: 'epaisseur' },
              { text: 'Poids', value: 'weight' },
              { text: 'Format', value: 'format.name' },
              { text: 'Impression', value: 'impression.name' },
              { text: 'Pelliculage', value: 'pelliculage.name' },
              {
                text: 'Papier couverture',
                value: 'papier_couverture.papier.name',
              },
              {
                text: 'Papier interieur',
                value: 'papier_interieur.papier.name',
              },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Etat', value: 'f1.name' },
              { text: 'Envoyé au serveur', value: 'f2' },
              { text: 'Acheve', value: 'acheve.name' },
              { text: 'Finition', value: 'finition.name' },
              { text: 'Version', value: 'version' },
              { text: 'Mis à jour le', value: 'updated_at' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Utilisateur', value: 'user.username' },
            ]"
            :sort-by="['updated_at']"
            :sort-desc="[true]"
            :items="last_products"
            :style="last_products.length > 0 ? 'white-space: nowrap' : ''"
            :footer-props="{
              'items-per-page-options': [
                5, 10, 15, 30, 50, 100, 200, 300, 500, 1000, -1,
              ],
              itemsPerPageText: 'Lignes par page',
            }"
          >
            <template #[`item.weight`]="{ item }"
              >{{ Math.round(parseFloat(item.weight)) }} g</template
            >
            <template #[`item.epaisseur`]="{ item }"
              >{{ Math.round(item.epaisseur * 2) / 2 }} mm</template
            >
            <template #[`item.action`]="{ item }">
              <div class="py-1 d-flex">
                <ProductsAdminDetail :product="item"></ProductsAdminDetail>
                <ProductAdminEdit
                  :product="item"
                  class="ml-1"
                ></ProductAdminEdit>
                <v-btn
                  depressed
                  color="red"
                  class="border-shaped ml-1"
                  @click="deleteProduct(item.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
            <template #[`item.created_at`]="{ item }">
              {{
                new Date(
                  new Date(item.created_at).toLocaleString('en-US') + ' UTC'
                ).toLocaleString()
              }}
            </template>
            <template #[`item.updated_at`]="{ item }">
              {{
                new Date(
                  new Date(item.updated_at).toLocaleString('en-US') + ' UTC'
                ).toLocaleString()
              }}
            </template>
            <template #[`item.enabled`]="{ item }">
              <v-switch
                v-model="item.enabled"
                class="mb-n3 mt-1"
                @change="toggleProduct(item.id)"
              ></v-switch>
            </template>
            <template #[`item.f2`]="{ item }">
              {{ item.f2 === 1 ? 'Oui' : 'Non' }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-divider class="my-6"></v-divider>
      <v-row>
        <v-col>
          <v-card-title>
            <div>Dernières commandes passées</div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search_orders"
              class="border-shaped"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
              outlined
            ></v-text-field>
          </v-card-title>
          <v-data-table
            class="border-shaped elevation-1"
            :loading="loading_last_orders"
            :search="search_orders"
            dense
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Uid', align: 'start', value: 'uid' },
              { text: 'Etat', value: 'f1.name' },
              { text: 'Prix Fabrication', value: 'prix_fabrication' },
              { text: 'Prix Livraison', value: 'prix_livraison' },
              { text: 'Prix Emballage', value: 'prix_emballage' },
              { text: 'Prix HT', value: 'prix_ht' },
              { text: 'Prix TTC', value: 'prix_ttc' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Utilisateur', value: 'user.username' },
              { text: 'Lignes de commande', value: 'lignes_commande' },
            ]"
            :sort-by="['updated_at']"
            :sort-desc="[true]"
            :items="last_orders"
            :style="last_orders.length > 0 ? 'white-space: nowrap' : ''"
            :footer-props="{
              'items-per-page-options': [
                5, 10, 15, 30, 50, 100, 200, 300, 500, 1000, -1,
              ],
              itemsPerPageText: 'Lignes par page',
            }"
          >
            <template #[`item.prix_fabrication`]="{ item }"
              >{{ parseFloat(item.prix_fabrication).toFixed(2) }} €</template
            >
            <template #[`item.prix_livraison`]="{ item }"
              >{{ parseFloat(item.prix_livraison).toFixed(2) }} €</template
            >
            <template #[`item.prix_emballage`]="{ item }"
              >{{ parseFloat(item.prix_emballage).toFixed(2) }} €</template
            >
            <template #[`item.prix_ttc`]="{ item }"
              >{{ parseFloat(item.prix_ttc).toFixed(2) }} €</template
            >
            <template #[`item.prix_ht`]="{ item }"
              >{{ parseFloat(item.prix_ht).toFixed(2) }} €</template
            >
            <template #[`item.action`]="{ item }">
              <div class="py-1 d-flex">
                <v-lazy>
                  <OrdersAdminDetail :id="item.id"></OrdersAdminDetail>
                </v-lazy>
                <v-btn
                  color="red"
                  class="border-shaped ml-1"
                  depressed
                  @click="deleteOrder(item.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
            <template #[`item.created_at`]="{ item }">
              {{
                new Date(
                  new Date(item.created_at).toLocaleString('en-US') + ' UTC'
                ).toLocaleString()
              }}
            </template>
            <template #[`item.updated_at`]="{ item }">
              {{
                new Date(
                  new Date(item.updated_at).toLocaleString('en-US') + ' UTC'
                ).toLocaleString()
              }}
            </template>
            <template #[`item.enabled`]="{ item }">
              <v-switch
                v-model="item.enabled"
                class="mb-n3 mt-1"
                @change="toggleOrder(item.id)"
              ></v-switch>
            </template>
            <template #[`item.lignes_commande`]="{ item }">
              <div>
                <span>{{ item.lignes_commande.length }}</span>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from 'axios'
import { EventBus } from '@/utils'
import ProductsAdminDetail from '@/components/admin/products/ProductsAdminDetail'
import ProductAdminEdit from '@/components/admin/products/ProductAdminEdit'
import OrdersAdminDetail from '@/components/admin/orders/OrdersAdminDetail'

export default {
  name: 'AccueilAdmin',
  components: {
    ProductsAdminDetail,
    ProductAdminEdit,
    OrdersAdminDetail,
  },
  data() {
    return {
      info: {
        message: '',
        enabled: true,
      },
      loading_info: false,
      last_products: [],
      loading_last_products: false,
      search_products: '',
      last_orders: [],
      loading_last_orders: false,
      search_orders: '',
    }
  },
  computed: {},
  mounted() {
    this.getInfos()
    EventBus.$on('refreshproducts', () => {
      this.getLastProducts()
    })
    EventBus.$on('refreshorders', () => {
      this.getLastOrders()
    })
    this.getLastProducts()
    this.getLastOrders()
  },
  methods: {
    getInfos() {
      axios
        .get(process.env.VUE_APP_API_URL + '/admin/information', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
          },
        })
        .then((response) => {
          this.info = response.data.information
          if (this.info.length > 0) {
            this.dialog = true
          }
        })
        .catch(() => {
          this.info = ''
        })
    },
    updateInfo() {
      this.loading_info = true
      axios
        .put(
          process.env.VUE_APP_API_URL + '/admin/information',
          {
            message: this.info.message,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
            },
          }
        )
        .then(() => {
          EventBus.$emit('notif', 'Information modifiée', 'success')
        })
        .catch(() => {
          EventBus.$emit('notif', "Une erreur s'est produite", 'error')
        })
        .finally(() => {
          this.loading_info = false
        })
    },
    toggleInfo() {
      axios
        .patch(
          process.env.VUE_APP_API_URL + '/admin/information',
          {},
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
            },
          }
        )
        .then(() => {
          EventBus.$emit('notif', 'Information modifiée', 'success')
          this.getInfos()
        })
        .catch(() => {
          EventBus.$emit('notif', "Une erreur s'est produite", 'error')
          this.getInfos()
        })
    },
    getLastProducts() {
      this.loading_last_products = true
      axios
        .get(process.env.VUE_APP_API_URL + '/admin/latest_products', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
          },
        })
        .then((response) => {
          this.last_products = response.data.products
        })
        .catch(() => {
          this.last_products = []
        })
        .finally(() => {
          this.loading_last_products = false
        })
    },
    toggleProduct(id) {
      axios
        .patch(process.env.VUE_APP_API_URL + '/admin/products/' + id, null, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
          },
        })
        .then(() => {
          EventBus.$emit('notif', 'Produit modifié', 'success')
          this.getLastProducts()
        })
        .catch(() => {
          EventBus.$emit('notif', "Une erreur s'est produite", 'error')
          this.getLastProducts()
        })
    },
    deleteProduct(id) {
      if (confirm('Êtes-vous sûr de vouloir supprimer ce produit ?')) {
        axios
          .delete(process.env.VUE_APP_API_URL + '/admin/products/' + id, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
            },
          })
          .then(() => {
            EventBus.$emit('notif', 'Produit supprimé', 'success')
            this.getLastProducts()
          })
          .catch(() => {
            EventBus.$emit('notif', "Une erreur s'est produite", 'error')
            this.getLastProducts()
          })
      }
    },
    getLastOrders() {
      this.loading_last_orders = true
      axios
        .get(process.env.VUE_APP_API_URL + '/admin/latest_orders', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
          },
        })
        .then((response) => {
          this.last_orders = response.data.commandes
        })
        .catch(() => {
          this.last_orders = []
        })
        .finally(() => {
          this.loading_last_orders = false
        })
    },
    toggleOrder(id) {
      axios
        .patch(process.env.VUE_APP_API_URL + '/admin/orders/' + id, null, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
          },
        })
        .then(() => {
          EventBus.$emit('notif', 'Commande modifiée', 'success')
          this.getLastOrders()
        })
        .catch(() => {
          EventBus.$emit('notif', "Une erreur s'est produite", 'error')
          this.getLastOrders()
        })
    },
    deleteOrder(id) {
      if (confirm('Êtes-vous sûr de vouloir supprimer cette commande ?')) {
        axios
          .delete(process.env.VUE_APP_API_URL + '/admin/orders/' + id, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
            },
          })
          .then(() => {
            EventBus.$emit('notif', 'Commande supprimée', 'success')
            this.getLastOrders()
          })
          .catch(() => {
            EventBus.$emit('notif', "Une erreur s'est produite", 'error')
            this.getLastOrders()
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
