<template>
  <section class="reset-password">
    <page-title
      :title="$t('user.change_password')"
      :breadcrumbs="items"
    ></page-title>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="changePassword"
    >
      <v-container>
        <v-row>
          <v-col cols="12" sm="4" xl="3" class="mx-auto mt-16">
            <v-alert v-if="errorMsg" type="error">
              {{ errorMsg }}
            </v-alert>
            <v-text-field
              v-model="password"
              name="password"
              class="border-shaped"
              :rules="passwordRules"
              type="password"
              :label="$t('form.password')"
              prepend-inner-icon="mdi-lock-outline"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="confirm_password"
              name="password"
              class="border-shaped"
              :rules="confirm_passwordRules"
              type="password"
              :label="$t('form.confirm_password')"
              prepend-inner-icon="mdi-lock-outline"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="code"
              class="border-shaped"
              name="code"
              label="Code"
              outlined
            ></v-text-field>
            <password
              v-model="password"
              :strength-meter-only="true"
              @feedback="showFeedback"
            />
            <v-list dense>
              <v-list-item v-for="(item, i) in feedback" :key="i">
                <v-list-item-icon>
                  <v-icon>mdi-information</v-icon>
                </v-list-item-icon>
                {{ item }}
              </v-list-item>
            </v-list>
            <v-btn
              block
              large
              depressed
              type="submit"
              class="border-shaped yellow--bg"
              :disabled="!valid"
              :loading="loading"
              @click="validate"
            >
              {{ $t('form.submit') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </section>
</template>

<script>
import PageTitle from '@/components/info/PageTitle'
import Password from 'vue-password-strength-meter'
import isEmpty from 'validator/lib/isEmpty'
import axios from 'axios'
import { EventBus } from '@/utils'

export default {
  name: 'ResetPassword',
  metaInfo: {
    title: 'Rapidbook - Reset Password',
    metas: [
      {
        name: 'com.silverpop.brandeddomains',
        content:
          'www.pages06.net,corlet.fr,rapidbook.fr,www.corlet.fr,www.rapidbook.fr',
      },
    ],
    script: [
      {
        src: 'https://www.sc.pages06.net/lp/static/js/iMAWebCookie.js?40625c3e-18bd96a8009-3c9d767ebba0be65b5fa68bf26a6ee93&h=www.pages06.net',
      },
    ],
  },
  components: {
    PageTitle,
    Password,
  },
  data() {
    return {
      valid: true,
      loading: false,
      password: '',
      confirm_password: '',
      code: this.$route.query.code,
      feedback: [],
      passwordRules: [(v) => !isEmpty(v) || this.$t('form.required.password')],
      confirm_passwordRules: [
        (v) => !isEmpty(v) || this.$t('form.required.confirm_password'),
        () =>
          this.password === this.confirm_password ||
          this.$t('form.passwords_dont_match'),
      ],
    }
  },
  computed: {
    items: function () {
      return [
        {
          text: 'Rapidbook',
          disabled: false,
          to: '/',
        },
        {
          text: this.$t('user.change_password'),
          disabled: true,
          to: '',
        },
      ]
    },
  },
  methods: {
    showFeedback({ suggestions }) {
      this.feedback = suggestions
    },
    changePassword() {
      this.loading = true
      this.$refs.form.validate()
      if (this.valid) {
        axios
          .put(process.env.VUE_APP_API_URL + '/reset_password', {
            password: this.password,
            confirm_password: this.confirm_password,
            code: this.code,
          })
          .then(() => {
            EventBus.$emit('notif', this.$t('form.password_changed'), 'success')
            this.$router
              .push({
                name: 'Login',
              })
              .catch(() => {})
          })
          .catch((e) => {
            EventBus.$emit('notif', e.response.data.message, 'error')
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.reset-password {
  min-height: calc(100vh - 64px);
}
</style>
