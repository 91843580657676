<template>
  <div>
    <v-btn
      class="border-shaped ml-2 yellow--bg"
      depressed
      @click="toggleDialog"
    >
      <v-icon v-if="item">mdi-pencil</v-icon>
      <v-icon v-else>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="900px">
      <v-form
        ref="formEditCategory"
        v-model="valid"
        @submit.prevent="submitForm"
      >
        <v-card shaped elevation="24">
          <v-card-title class="text-h4 text-lg-h3 font-weight-bold">
            {{ item ? 'Éditer Catégorie' : 'Ajouter Catégorie' }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-model="name"
              label="Libellé"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="tva"
              label="TVA"
              outlined
              clearable
              class="border-shaped"
              type="number"
              step="0.1"
            ></v-text-field>
            <v-switch v-model="enabled" label="Activé/Désactivé"></v-switch>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="border-shaped yellow--bg ml-auto mb-3"
              type="submit"
              :loading="loading"
              elevation="0"
            >
              Valider
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { EventBus } from '@/utils'
export default {
  props: ['item'],
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      name: this.item ? this.item.name : '',
      tva: this.item ? this.item.tva : 5.5,
      enabled: this.item ? this.item.enabled : true,
    }
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog
    },
    submitForm() {
      this.$refs.formEditCategory.validate()
      if (this.valid) {
        this.loading = true
        const data = {
          name: this.name,
          tva: this.tva,
          enabled: this.enabled,
        }

        if (this.item) {
          axios
            .put(
              process.env.VUE_APP_API_URL + '/admin/categories/' + this.item.id,
              data,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
                },
              }
            )
            .then(() => {
              EventBus.$emit('refreshcategories')
              this.dialog = false
              this.loading = false
              EventBus.$emit('notif', 'Catégorie modifiée', 'success')
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          axios
            .post(process.env.VUE_APP_API_URL + '/admin/categories', data, {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
              },
            })
            .then(() => {
              EventBus.$emit('refreshcategories')
              this.dialog = false
              this.loading = false
              EventBus.$emit('notif', 'Catégorie ajoutée', 'success')
            })
            .catch(() => {
              this.loading = false
            })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
