<template>
  <section class="help">
    <page-title :title="$t('title')" :breadcrumbs="items"></page-title>
    <v-container>
      <v-row>
        <v-col cols="12" md="10" class="mx-auto">
          <v-expansion-panels v-model="panels" flat focusable>
            <v-expansion-panel class="border-shaped">
              <v-expansion-panel-header class="text-h5 font-weight-bold mb-3"
                >{{ $t('tuto_simulate') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex justify-center">
                  <v-lazy transition="scale-transition" class="tuto-video">
                    <video
                      src="@/assets/tuto/Simulation tarifaire 1.mp4"
                      controls
                      autoplay
                      onloadstart="this.volume=0.33"
                      class="tuto-video border-shaped"
                      ref="video-1"
                    ></video>
                  </v-lazy>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="border-shaped">
              <v-expansion-panel-header class="text-h5 font-weight-bold mb-3">
                {{ $t('tuto_add_address') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex justify-center">
                  <v-lazy transition="scale-transition" class="tuto-video">
                    <video
                      src="@/assets/tuto/Adresse expedition 2.mp4"
                      controls
                      autoplay
                      onloadstart="this.volume=0.33"
                      class="tuto-video border-shaped"
                      ref="video-2"
                    ></video>
                  </v-lazy>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="border-shaped">
              <v-expansion-panel-header class="text-h5 font-weight-bold mb-3">
                {{ $t('tuto_add_title') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex justify-center">
                  <v-lazy transition="scale-transition" class="tuto-video">
                    <video
                      src="@/assets/tuto/Ajout titre 3.mp4"
                      controls
                      autoplay
                      onloadstart="this.volume=0.33"
                      class="tuto-video border-shaped"
                      ref="video-3"
                    ></video>
                  </v-lazy>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="border-shaped">
              <v-expansion-panel-header class="text-h5 font-weight-bold mb-3">
                {{ $t('tuto_order') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex justify-center">
                  <v-lazy transition="scale-transition" class="tuto-video">
                    <video
                      src="@/assets/tuto/Passage commande 4.mp4"
                      controls
                      autoplay
                      onloadstart="this.volume=0.33"
                      class="tuto-video border-shaped"
                      ref="video-4"
                    ></video>
                  </v-lazy>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script lang="js">
import PageTitle from '@/components/info/PageTitle'

export default {
  name: 'Help',
  metaInfo: {
    title: 'Rapidbook - Help',
    metas: [
      {
        name: 'com.silverpop.brandeddomains',
        content:
          'www.pages06.net,corlet.fr,rapidbook.fr,www.corlet.fr,www.rapidbook.fr',
      },
    ],
    script: [
      {
        src: 'https://www.sc.pages06.net/lp/static/js/iMAWebCookie.js?40625c3e-18bd96a8009-3c9d767ebba0be65b5fa68bf26a6ee93&h=www.pages06.net',
      },
    ],
  },
  components: { PageTitle },
  props: [],
  data() {
    return {
      panels: null,
    }
  },
  computed: {
    items: function () {
      return [
        {
          text: 'Rapidbook',
          disabled: false,
          to: '/'
        },
        {
          text: this.$t('title'),
          disabled: true,
          to: ''
        }
      ]
    }
  },
  watch: {
    panels: function () {
      for (let i = 0; i < 4; i++) {
        try {
          i !== this.panels ? this.$refs[`video-${i+1}`].pause() : null
        }
        catch (error) {
            return
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.help {
  min-height: calc(100vh - 64px);
}

.tuto-video {
  width: 100%;
}
</style>

<i18n lang="json">
{
  "fr": {
    "title": "Besoin d'aide ?",
    "tuto_add_address": "Ajouter une adresse ?",
    "tuto_add_title": "Ajouter un titre ?",
    "tuto_order": "Passer commande ?",
    "tuto_simulate": "Faire un devis ?"
  },
  "en": {
    "title": "Need help ?",
    "tuto_add_address": "Add address ?",
    "tuto_add_title": "Add title ?",
    "tuto_order": "Order ?",
    "tuto_simulate": "Simulate ?"
  }
}
</i18n>
