<template>
  <div>
    <div class="mb-3 d-flex align-center">
      <v-icon class="orange--text">mdi-pin</v-icon>:
      {{ $t('address.billing') }}
    </div>
    <div class="mb-3 d-flex align-center">
      <v-icon class="orange--text">mdi-star</v-icon>:
      {{ $t('address.favorite') }}
    </div>
    <div v-if="loading" class="text-center">
      <v-progress-circular
        :size="150"
        color="yellow"
        indeterminate
        class="my-10"
      >
        {{ $t('loading') }}
      </v-progress-circular>
    </div>
    <v-data-iterator
      v-else
      :items="addresses"
      hide-default-footer
      :items-per-page="-1"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :search="search"
    >
      <template #header>
        <div class="d-md-flex">
          <v-select
            v-model="sortBy"
            :items="[
              { text: $t('address.favorite'), value: 'favorite' },
              { text: $t('address.billing'), value: 'pinned' },
              { text: $t('address.address'), value: 'address' },
              { text: $t('address.city'), value: 'city' },
              { text: $t('address.postal_code'), value: 'code_postal' },
              { text: $t('address.country'), value: 'country.iso' },
              { text: $t('global.phone'), value: 'phone' },
              { text: $t('global.email'), value: 'email' },
              { text: $t('global.created_at'), value: 'created_at' },
            ]"
            :label="$t('sort')"
            outlined
            class="border-shaped mr-2"
          ></v-select>
          <v-btn-toggle v-model="sortDesc" mandatory class="mt-n4 mt-md-0">
            <v-btn x-large class="border-shaped" :value="false">
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn x-large class="border-shaped mx-2" :value="true">
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-text-field
            v-model="search"
            outlined
            :label="$t('form.search')"
            prepend-inner-icon="mdi-magnify"
            class="border-shaped mt-3 mt-md-0"
          ></v-text-field>
        </div>
      </template>
      <template #default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.id"
            cols="12"
            sm="6"
            md="6"
            lg="4"
            xl="3"
          >
            <v-lazy transition="scale-transition">
              <v-card shaped elevation="3">
                <v-card-text>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <strong>{{ item.address_full }}</strong>
                        <v-divider class="my-4"></v-divider>
                        <div class="overflow-y-auto" style="height: 200px">
                          <div>
                            <strong>{{ $t('address.contact') }}</strong
                            >: {{ item.contact }}
                          </div>
                          <div>
                            <strong>{{ $t('address.raison_sociale') }}</strong
                            >:
                            <span v-if="item.raison_sociale">
                              {{ item.raison_sociale }}</span
                            >
                            <span
                              v-else
                              class="subtitle-2 grey--text font-italic"
                            >
                              {{ $t('address.no_raison_sociale') }}
                            </span>
                          </div>
                          <div>
                            <strong>{{ $t('address.address') }}</strong> :
                            {{ item.address }}
                          </div>
                          <div v-if="item.complement">
                            <strong>{{ $t('address.complement') }}</strong> :
                            {{ item.complement }}
                          </div>
                          <div>
                            <strong>{{ $t('address.postal_code') }}</strong> :
                            {{ item.code_postal }}
                          </div>
                          <div>
                            <strong>{{ $t('address.city') }}</strong> :
                            {{ item.city }}
                          </div>
                          <div>
                            <strong>{{ $t('address.country') }}</strong> :
                            {{ item.country.name }}
                          </div>
                          <div v-if="item.phone_mobile">
                            <strong>{{ $t('address.phone.mobile') }}</strong> :
                            {{ item.phone_mobile }}
                          </div>
                          <div v-if="item.phone_fix">
                            <strong>{{ $t('address.phone.fix') }}</strong> :
                            {{ item.phone_fix }}
                          </div>
                          <div>
                            <strong>{{ $t('global.email') }}</strong> :
                            {{ item.email }}
                          </div>
                          <div>
                            <strong>{{ $t('address.type') }}</strong> :
                            {{ item.address_type.name }}
                          </div>
                          <strong v-if="item.comment"
                            >{{ $t('address.comment') }} :</strong
                          >
                          <div>
                            <i>{{ item.comment }}</i>
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <i class="text-caption text--secondary ml-2"
                    >{{ $t('global.created_at') }} :
                    {{
                      new Date(
                        new Date(item.created_at).toLocaleString('en-US') +
                          ' UTC'
                      ).toLocaleString()
                    }}</i
                  >
                  <v-item-group>
                    <v-btn
                      icon
                      large
                      color="orange"
                      class="ml-auto"
                      @click="pinAddress(item.id, item.pinned)"
                    >
                      <v-icon v-if="item.pinned">mdi-pin</v-icon>
                      <v-icon v-else>mdi-pin-outline</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      large
                      color="orange"
                      class="ml-auto"
                      @click="favoriteAddress(item.id)"
                    >
                      <v-icon v-if="item.favorite">mdi-star</v-icon>
                      <v-icon v-else>mdi-star-outline</v-icon>
                    </v-btn>
                  </v-item-group>
                  <v-btn
                    color="red"
                    class="border-shaped"
                    depressed
                    @click="deleteAddress(item.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-lazy>
          </v-col>
        </v-row>
      </template>
      <template #no-data>
        <i class="ml-1">{{ $t('no_data') }}</i>
      </template>
    </v-data-iterator>
  </div>
</template>

<script lang="js">

import axios from 'axios'
import { EventBus } from '@/utils'

export default {
  name: 'AddressList',
  props: [],
  data() {
    return {
      loading: false,
      addresses: [],
      sortBy: 'pinned',
      sortDesc: true,
      search: ''
    }
  },
  computed: {},
  mounted() {
    this.getAddresses()
    EventBus.$on('refreshAddresses', () => {
      this.getAddresses()
    })
  },
  methods: {
    getAddresses() {
      this.loading = true
      axios
        .get(process.env.VUE_APP_API_URL + '/address', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`
          }
        })
        .then(response => {
          this.addresses = response.data.addresses
          this.loading = false
        })
    },
    deleteAddress(id) {
      if (confirm('Êtes-vous sûr ?')) {
        axios.delete(process.env.VUE_APP_API_URL + '/address/' + id, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`
          }
        })
          .then(() => {
            this.getAddresses()
            EventBus.$emit('notif', 'Adresse supprimée', 'success')
          })
          .catch(() => {
            EventBus.$emit('notif', 'Une erreur est survenue', 'error')
          })
      }
    },
    pinAddress(id, pinned) {
      if (!pinned) {
        axios.patch(process.env.VUE_APP_API_URL + '/address/' + id, {}, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`
          }
        })
          .then(() => {
            this.getAddresses()
            EventBus.$emit('notif', 'Adresse mis à jour', 'success')
          })
          .catch(() => {
            EventBus.$emit('notif', 'Une erreur est survenue', 'error')
          })
      }
    },
    favoriteAddress(id) {
      axios.patch(process.env.VUE_APP_API_URL + '/address/' + id, {
        favorite: true
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getJwtToken}`
        }
      })
        .then(() => {
          this.getAddresses()
          EventBus.$emit('notif', 'Adresse mis à jour', 'success')
        })
        .catch(() => {
          EventBus.$emit('notif', 'Une erreur est survenue', 'error')
        })
    }
  }
}
</script>

<i18n lang="json">
{
  "fr": {
    "sort": "Trier par",
    "date": "Date",
    "items_per_page": "Produits par page",
    "no_data": "Aucune adresse enregistrée"
  },
  "en": {
    "sort": "Sort by",
    "date": "Date",
    "items_per_page": "Products per page",
    "no_data": "No address registered"
  }
}
</i18n>
