var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{staticClass:"border-shaped ml-2 mb-2 yellow--bg",attrs:{"depressed":""},on:{"click":_vm.toggleDialog}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-dialog',{attrs:{"max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"formEditOrder",on:{"submit":function($event){$event.preventDefault();return _vm.editLine.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{attrs:{"shaped":"","elevation":"24"}},[_c('v-card-title',{staticClass:"text-h4 text-lg-h3 font-weight-bold"},[_vm._v(" Editer ligne commande ")]),_c('v-divider'),_c('v-card-text',[_c('v-text-field',{staticClass:"border-shaped",attrs:{"type":"number","label":"Quantité","outlined":"","clearable":"","rules":_vm.quantity_rules},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}}),_c('v-row',[_c('v-col',[_c('v-autocomplete',{staticClass:"border-shaped",attrs:{"label":"Adresse","outlined":"","clearable":"","items":_vm.addresses,"rules":_vm.address_rules,"item-text":(item) =>
                    (item.pinned ? '⭐' : '') +
                    ' ' +
                    item.address_full +
                    ' - ' +
                    item.country.name +
                    ' ' +
                    (item.complement ? item.complement : '') +
                    ' ' +
                    (item.contact ? item.contact : '') +
                    ' ' +
                    (item.raison_sociale ? item.raison_sociale : '') +
                    ' ' +
                    (item.email ? item.email : ''),"return-object":""},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-list-item-content',{staticClass:"text-caption"},[_c('strong',[_vm._v(_vm._s(item.address_full)+" "+_vm._s(item.pinned ? '⭐' : ''))]),_c('div',{staticClass:"overflow-y-auto"},[_c('div',[_c('strong',[_vm._v("Contact")]),_vm._v(": "+_vm._s(item.contact))]),_c('div',[_c('strong',[_vm._v("Raison sociale")]),_vm._v(": "),(item.raison_sociale)?_c('span',[_vm._v(" "+_vm._s(item.raison_sociale))]):_c('span',{staticClass:"grey--text font-italic"},[_vm._v(" Pas de raison sociale ")])]),_c('div',[_c('strong',[_vm._v("Adresse")]),_vm._v(" : "+_vm._s(item.address)+" ")]),(item.complement)?_c('div',[_c('strong',[_vm._v("Complément")]),_vm._v(" : "+_vm._s(item.complement)+" ")]):_vm._e(),_c('div',[_c('strong',[_vm._v("Code postal")]),_vm._v(" : "+_vm._s(item.code_postal)+" ")]),_c('div',[_c('strong',[_vm._v("Ville")]),_vm._v(" : "+_vm._s(item.city))]),_c('div',[_c('strong',[_vm._v("Pays")]),_vm._v(" : "+_vm._s(item.country.name)+" ")]),(item.phone_mobile)?_c('div',[_c('strong',[_vm._v("Mobile")]),_vm._v(" : "+_vm._s(item.phone_mobile)+" ")]):_vm._e(),(item.phone_fix)?_c('div',[_c('strong',[_vm._v("Fixe")]),_vm._v(" : "+_vm._s(item.phone_fix)+" ")]):_vm._e(),_c('div',[_c('strong',[_vm._v("Mail")]),_vm._v(" : "+_vm._s(item.email))]),_c('div',[_c('strong',[_vm._v("Type")]),_vm._v(" : "+_vm._s(item.address_type.name)+" ")])])])]}}]),model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)],1),_c('v-textarea',{attrs:{"label":"Commentaire","outlined":"","counter":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"border-shaped yellow--bg ml-auto",attrs:{"type":"submit","loading":_vm.loading,"depressed":"","large":""}},[_vm._v(" Valider ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }