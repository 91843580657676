<template>
  <v-dialog v-model="dialog" width="900" scrollable>
    <template #activator="{ on, attrs }">
      <v-btn
        class="ml-auto yellow--bg border-shaped"
        v-bind="attrs"
        depressed
        v-on="on"
      >
        <v-icon>mdi-information</v-icon>
      </v-btn>
    </template>
    <v-card v-if="product" shaped elevation="24">
      <v-btn
        depressed
        class="border-shaped yellow--bg"
        style="position: absolute; top: 10px; right: 5px; z-index: 10"
        @click="dialog = !dialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-lazy v-if="dialog">
        <v-hover v-slot:="{ hover }">
          <v-img
            :src="coverUrl"
            :lazy-src="coverUrl"
            :class="
              hover
                ? 'img-detail img-detail-hover border-shaped'
                : 'img-detail border-shaped'
            "
          ></v-img>
        </v-hover>
      </v-lazy>
      <v-card-title class="text-h5 text-lg-h4 font-weight-bold">
        {{ product.title }}
      </v-card-title>
      <v-card-subtitle class="my-0 subtitle-2">
        <strong>État </strong>: {{ product.f1.name }}
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text class="black--text">
        <v-list>
          <v-alert
            type="warning"
            dense
            text
            class="border-shaped mt-2"
            v-if="product.impression.id === 2 && product.page_count % 4 !== 0"
          >
            {{ $t('product.message.print_color_page_count') }}
          </v-alert>
          <div class="text-h4 font-weight-bold ml-n1 my-2">Général</div>
          <v-list-item
            ><v-list-item-content
              ><v-list-item-title
                ><strong>Id</strong> : {{ product.uid }}</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          >
          <v-list-item
            ><v-list-item-content
              ><v-list-item-title
                ><strong>Titre</strong> : {{ product.title }}</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          >
          <v-list-item
            ><v-list-item-content
              ><v-list-item-title
                ><strong>ISBN</strong> : {{ product.isbn }}</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          >
          <v-list-item
            ><v-list-item-content
              ><v-list-item-title
                ><strong>Auteur</strong> :
                {{ product.author }}</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          >
          <v-list-item
            ><v-list-item-content
              ><v-list-item-title
                ><strong>Référence</strong> :
                {{ product.reference }}</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          >
          <v-list-item
            ><v-list-item-content
              ><v-list-item-title
                ><strong>Catégorie</strong> : {{ product.category.name }} - TVA
                : {{ product.category.tva }}%</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          >
          <v-list-item v-if="product.finition !== null"
            ><v-list-item-content
              ><v-list-item-title
                ><strong>Finition</strong> :
                {{ product.finition.name }}</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          >
          <v-list-item v-if="product.acheve !== null"
            ><v-list-item-content
              ><v-list-item-title
                ><strong>Achevé</strong> :
                {{ product.acheve.name }}</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          >
          <v-list-item v-else
            ><v-list-item-content
              ><v-list-item-title
                ><strong>Achevé</strong> : Pas d'achevé</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          >
          <v-list-item
            ><v-list-item-content
              ><v-list-item-title
                ><strong>Format</strong> :
                {{ product.format.name }}</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          >
          <v-list-item
            ><v-list-item-content
              ><v-list-item-title
                ><strong>Pages</strong> :
                {{ product.page_count }}</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          >
          <v-list-item
            ><v-list-item-content
              ><v-list-item-title
                ><strong>Epaisseur</strong> :
                {{ Math.round(product.epaisseur * 2) / 2 }}
                mm</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          >
          <v-list-item
            ><v-list-item-content
              ><v-list-item-title
                ><strong>Poids</strong> :
                {{ Math.round(parseFloat(product.weight)) }}
                g</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          >
          <v-list-item
            ><v-list-item-content
              ><v-list-item-title
                ><strong>Version</strong> :
                {{ product.version }}</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          >
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title
                ><strong>Crée le</strong> :
                {{
                  new Date(
                    new Date(product.created_at).toLocaleString('en-US') +
                      ' UTC'
                  ).toLocaleString()
                }}</v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title
                ><strong>Mis à jour le</strong> :
                {{
                  new Date(
                    new Date(product.updated_at).toLocaleString('en-US') +
                      ' UTC'
                  ).toLocaleString()
                }}</v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title
                ><strong>Propriétaire</strong> :
                {{
                  product.user
                    ? product.user.username + ' - ' + product.user.real_name
                    : 'Utilisateur inexistant'
                }}</v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title
                ><strong>Mis à jour par</strong> :
                {{
                  product.last_modifier
                    ? product.last_modifier.username +
                      ' - ' +
                      product.last_modifier.real_name
                    : ''
                }}</v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
        </v-list>
        <v-divider class="mt-2"></v-divider>
        <v-list>
          <div class="text-h4 font-weight-bold ml-n1 my-2">Couverture</div>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                ><strong>PDF Couverture</strong></v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn
                v-if="product.path_couverture"
                class="border-shaped yellow--bg mt-n3"
                depressed
                :loading="loading_couverture"
                @click="downloadPDF(product, 2)"
              >
                Télécharger
              </v-btn>
              <div v-else class="font-italic">
                {{ $t('product.pdf.no_file') }}
              </div>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                ><strong>Papier</strong> :
                {{
                  $i18n.locale === 'fr'
                    ? product.papier_couverture.papier.name
                    : product.papier_couverture.papier.en_name
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                ><strong>Pelliculage</strong> :
                {{ product.pelliculage.name }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <strong>Recto/Verso</strong> :
                {{
                  product.is_couv_recto_verso ? 'Oui' : 'Non'
                }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-expansion-panels flat accordion hover>
            <v-expansion-panel>
              <v-expansion-panel-header
                ><strong>Zone de rogne</strong></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <ul>
                  <li v-for="(value, k) in product.trim_box_couv" :key="k">
                    <strong>{{ k }}</strong> : {{ value }}
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                ><strong>Format du média</strong></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <ul>
                  <li v-for="(value, k) in product.media_box_couv" :key="k">
                    <strong>{{ k }}</strong> : {{ value }}
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <strong>Zone de fonds perdus</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                  <li v-for="(value, k) in product.bleed_box_couv" :key="k">
                    <strong>{{ k }}</strong> : {{ value }}
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                ><strong>Métadonnées</strong></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <ul>
                  <li v-for="(value, k) in product.meta_data_couv" :key="k">
                    <strong>{{ k }}</strong> : {{ value }}
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-list>
        <v-divider class="mt-2"></v-divider>
        <v-list>
          <div class="text-h4 font-weight-bold ml-n1 my-2">Intérieur</div>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <strong>PDF Intérieur</strong></v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn
                v-if="product.path_interieur"
                class="border-shaped yellow--bg mt-n3"
                depressed
                :loading="loading_interieur"
                @click="downloadPDF(product, 1)"
              >
                Télécharger
              </v-btn>
              <div v-else class="font-italic">
                {{ $t('product.pdf.no_file') }}
              </div>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <strong>Papier</strong> :
                {{
                  $i18n.locale === 'fr'
                    ? product.papier_interieur.papier.name
                    : product.papier_interieur.papier.en_name
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <strong>Impression</strong> : {{ product.impression.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <strong>Bords Perdus</strong> :
                {{ product.is_bords_perdus ? 'Oui' : 'Non' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-expansion-panels flat accordion hover>
          <v-expansion-panel>
            <v-expansion-panel-header
              ><strong>Zone de rogne</strong></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <ul>
                <li v-for="(value, k) in product.trim_box_int" :key="k">
                  <strong>{{ k }}</strong> : {{ value }}
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              ><strong>Format du média</strong></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <ul>
                <li v-for="(value, k) in product.media_box_int" :key="k">
                  <strong>{{ k }}</strong> : {{ value }}
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              ><strong>Zone de fonds perdus</strong></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <ul>
                <li v-for="(value, k) in product.bleed_box_int" :key="k">
                  <strong>{{ k }}</strong> : {{ value }}
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              ><strong>Métadonnées</strong></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <ul>
                <li v-for="(value, k) in product.meta_data_int" :key="k">
                  <strong>{{ k }}</strong> : {{ value }}
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-list v-if="![null, 'null', ''].includes(product.comment)">
          <div class="text-h4 font-weight-bold ml-n1 my-2">Commentaire</div>
          {{ product.comment }}
        </v-list>
        <v-divider class="mt-2"></v-divider>
        <v-list v-if="productOrders.length > 0" dense>
          <div class="text-h4 font-weight-bold ml-n1 my-2">Commandes</div>
          <v-data-table
            :headers="headers"
            :items="productOrders"
            :items-per-page="5"
          >
            <template #[`item.lignes_commande`]="{ item }">
              <OrdersAdminDetail :id="item.id"></OrdersAdminDetail>
            </template>
            <template #[`item.prix_ttc`]="{ item }">
              {{ parseFloat(item.prix_ttc).toFixed(2) }} €
            </template>
            <template #[`item.created_at`]="{ item }">
              {{
                new Date(
                  new Date(item.created_at).toLocaleString('en-US') + ' UTC'
                ).toLocaleString()
              }}
            </template>
          </v-data-table>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <product-admin-edit
          :product="product"
          class="ml-auto"
        ></product-admin-edit>
        <v-btn
          class="border-shaped ml-1"
          color="red"
          depressed
          @click="deleteProduct(product.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="js">
import axios from 'axios'
import { EventBus } from '@/utils'
import OrdersAdminDetail from '@/components/admin/orders/OrdersAdminDetail'
import ProductAdminEdit from '@/components/admin/products/ProductAdminEdit'

export default {
  name: 'ProductsAdminDetail',
  components: { OrdersAdminDetail, ProductAdminEdit },
  props: ['product'],
  data() {
    return {
      dialog: null,
      loading: true,
      loading_interieur: false,
      loading_couverture: false,
      apiUrl: process.env.VUE_APP_API_URL,
      headers: [
        { text: 'Id', align: 'start', value: 'uid' },
        { text: 'Etat', value: 'f1.name' },
        { text: 'Prix TTC', value: 'prix_ttc' },
        { text: 'Quantité commandée', value: 'quantity' },
        { text: 'Crée le', value: 'created_at' },
        { text: 'Action', value: 'lignes_commande' }
      ],
      coverUrl: '',
    }
  },
  computed: {
    productOrders: function () {
      if (!this.product) {
        return []
      }
      const map = {}
      const orders = []
      this.product.lignes_commande.forEach(l => {
        if (l.enabled) {
          if (!map[JSON.stringify(l.commande)]) {
            map[JSON.stringify(l.commande)] = true
            l.commande.quantity = l.quantity
            orders.push(l.commande)
          } else {
            orders.find(o => o.id === l.commande.id).quantity += l.quantity
          }
        }
      })
      return orders
    },
    performanceNow: function () {
      return performance.now()
    }
  },
  mounted() {
    // this.getCoverUrl()
  },
  methods: {
    getCoverUrl() {
      axios.get(this.apiUrl + '/product/' + this.product.id + '/vignette', {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getJwtToken}`
        },
        responseType: 'blob'
      }).then(response => {
        this.coverUrl = window.URL.createObjectURL(new Blob([response.data]))
      })
    },
    downloadPDF(product, type) {
      switch (type) {
        case 1:
          this.loading_interieur = true
          break
        case 2:
          this.loading_couverture = true
          break
      }
      axios
        .get(process.env.VUE_APP_API_URL + '/product/' + product.id + '/pdf', {
          params: {
            type: type
          },
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`
          },
          responseType: 'blob'
        })
        .then((response) => {
          this.loading_interieur = false
          this.loading_couverture = false
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', product.isbn + (type === 1 ? '_I' : '_C') + '.pdf') // or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          this.loading_interieur = false
          this.loading_couvertyre = false
          EventBus.$emit('notif', 'Impossible de récupérer le PDF', 'error')
        })
    },
    deleteProduct(id) {
      if (confirm('Êtes-vous sûr de vouloir supprimer ce produit ?')) {
        axios
          .delete(process.env.VUE_APP_API_URL + '/product/' + id, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
          .then(() => {
            this.dialog = false
            EventBus.$emit('refreshProducts')
            EventBus.$emit('refreshPanier')
          })
      }
    }
  },
  watch: {
    dialog: function (val) {
      if (val) {
        this.getCoverUrl()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.book {
  min-height: 50vh;

  .book-card {
    min-height: 100%;
  }

  .book-text {
    position: absolute;
    bottom: 0;
    background: var(--color-yellow);
    opacity: 0.85;
    height: 40%;
    transition: height 0.3s, opacity 0.3s;
  }

  .book-text-hover {
    height: 100%;
    opacity: 0.99;
    cursor: pointer;
  }

  .book-state {
    position: absolute;
    right: 5px;
    bottom: 5px;
  }
}

.img-detail {
  height: 150px;
  transition: height 0.3s;
}

.img-detail-hover {
  height: 39em;

  @media screen and (max-width: 600px) {
    height: 20em;
  }
}
</style>
