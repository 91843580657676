<template>
  <div>
    <v-btn
      class="border-shaped ml-2 mb-2 yellow--bg"
      depressed
      @click="toggleDialog"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="900px">
      <v-form ref="formEditOrder" v-model="valid" @submit.prevent="editLine">
        <v-card shaped elevation="24">
          <v-card-title class="text-h4 text-lg-h3 font-weight-bold">
            Editer ligne commande
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-model="quantity"
              type="number"
              label="Quantité"
              outlined
              clearable
              class="border-shaped"
              :rules="quantity_rules"
            ></v-text-field>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="address"
                  label="Adresse"
                  class="border-shaped"
                  outlined
                  clearable
                  :items="addresses"
                  :rules="address_rules"
                  :item-text="
                    (item) =>
                      (item.pinned ? '⭐' : '') +
                      ' ' +
                      item.address_full +
                      ' - ' +
                      item.country.name +
                      ' ' +
                      (item.complement ? item.complement : '') +
                      ' ' +
                      (item.contact ? item.contact : '') +
                      ' ' +
                      (item.raison_sociale ? item.raison_sociale : '') +
                      ' ' +
                      (item.email ? item.email : '')
                  "
                  return-object
                >
                  <template #item="{ item }">
                    <v-list-item-content class="text-caption">
                      <strong
                        >{{ item.address_full }}
                        {{ item.pinned ? '⭐' : '' }}</strong
                      >
                      <div class="overflow-y-auto">
                        <div><strong>Contact</strong>: {{ item.contact }}</div>
                        <div>
                          <strong>Raison sociale</strong>:
                          <span v-if="item.raison_sociale">
                            {{ item.raison_sociale }}</span
                          >
                          <span v-else class="grey--text font-italic">
                            Pas de raison sociale
                          </span>
                        </div>
                        <div>
                          <strong>Adresse</strong> :
                          {{ item.address }}
                        </div>
                        <div v-if="item.complement">
                          <strong>Complément</strong> :
                          {{ item.complement }}
                        </div>
                        <div>
                          <strong>Code postal</strong> :
                          {{ item.code_postal }}
                        </div>
                        <div><strong>Ville</strong> : {{ item.city }}</div>
                        <div>
                          <strong>Pays</strong> :
                          {{ item.country.name }}
                        </div>
                        <div v-if="item.phone_mobile">
                          <strong>Mobile</strong> :
                          {{ item.phone_mobile }}
                        </div>
                        <div v-if="item.phone_fix">
                          <strong>Fixe</strong> :
                          {{ item.phone_fix }}
                        </div>
                        <div><strong>Mail</strong> : {{ item.email }}</div>
                        <div>
                          <strong>Type</strong> : {{ item.address_type.name }}
                        </div>
                      </div>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-textarea
              v-model="comment"
              label="Commentaire"
              outlined
              counter
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="border-shaped yellow--bg ml-auto"
              type="submit"
              :loading="loading"
              depressed
              large
            >
              Valider
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script lang="js">

import axios from 'axios'
import { EventBus } from '@/utils'

export default {
  name: 'LinesOrderAdminEdit',
  props: ['line'],
  data () {
    return {
      opened: false,
      dialog: null,
      valid: false,
      loading: null,
      quantity: this.line.quantity,
      address: this.line.address,
      comment: this.line.comment,
      addresses: [],
      quantity_rules: [
        v => v !== null || 'Quantité requise',
        v => new RegExp('^\\d+$').test(v) || 'Doit contenir que des chiffres',
        v => v > 0 || 'Quantité supérieure à 0',
        v => !((this.line.product.finition !== null ? this.line.product.finition.id === 2 : true) && v < 50) || 'Quantité supérieure à 50 pour des produits piqués'
      ],
      address_rules: [v => v !== null || 'Adresse requise']
    }
  },
  computed: {},
  mounted () {
    EventBus.$on('refreshaddresses', () => {
      this.getAddresses()
    })
  },
  methods: {
    toggleDialog () {
      this.dialog = !this.dialog
      if (this.dialog && !this.opened) {
        this.getAddresses()
        this.opened = true
      }
    },
    getAddresses () {
      this.loading = true
      axios
        .get(process.env.VUE_APP_API_URL + '/admin/addresses', {
          params: {
            line_id: this.line.id
          },
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`
          }
        })
        .then(response => {
          this.addresses = response.data.items
          this.loading = false
        })
    },
    editLine (e) {
      e.preventDefault()
      this.$refs.formEditOrder.validate()
      if (this.quantity === null || this.quantity <= 0) {
        return false
      }
      if (this.address_id === null) {
        return false
      }
      if (this.valid) {
        this.loading = true
        const data = {
          quantity: this.quantity,
          address_id: this.address.id,
          comment: this.comment
        }
        axios
          .put(
            process.env.VUE_APP_API_URL + '/admin/lines/' + this.line.id,
            data,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getJwtToken}`
              }
            })
          .then(() => {
            EventBus.$emit('refreshproducts')
            EventBus.$emit('refreshorders')
            EventBus.$emit('refreshlines')
            this.dialog = false
            this.loading = false
            EventBus.$emit('notif', 'Ligne modifiée', 'success')
          })
          .catch((e) => {
            this.loading = false
            EventBus.$emit('notif', e.response.data.message, 'error')
          })
      }
    }
  }
}
</script>
