<template>
  <v-lazy
    tag="img"
    :src="imageUrl"
    v-if="imageUrl"
    transition="fade-transition"
  ></v-lazy>
</template>

<script>
import axios from 'axios'
export default {
  name: 'VignetteFetcher',
  props: ['id'],
  data() {
    return {
      imageUrl: null,
      apiUrl: process.env.VUE_APP_API_URL,
    }
  },
  mounted() {
    axios
      .get(this.apiUrl + '/product/' + this.id + '/vignette', {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
        },
        responseType: 'blob',
      })
      .then((response) => {
        this.imageUrl = window.URL.createObjectURL(new Blob([response.data]))
      })
  },
}
</script>

<style lang="scss" scoped></style>
