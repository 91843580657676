import Vue from 'vue'
import App from './App.vue'

import cookieconsent from 'vue-cookieconsent-component'
import CountryFlag from 'vue-country-flag'
import VueGtag from 'vue-gtag'
import VueMeta from 'vue-meta'
import i18n from './i18n'

import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import VNumeric from 'vuetify-numeric/vuetify-numeric.umd.min'

import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = true
Vue.config.silent = false

Vue.component('CookieConsent', cookieconsent)
Vue.component('CountryFlag', CountryFlag)

Vue.use(VNumeric)
Vue.use(VueTelInputVuetify, {
  vuetify,
})
Vue.use(
  VueGtag,
  {
    pageTrackerExcludedRoutes: ['Admin', 'Register'],
    config: {
      id: process.env.VUE_APP_GTAG_ID,
    },
  },
  router
)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
