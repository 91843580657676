<template>
  <section class="product-grid">
    <div v-if="loading" class="text-center">
      <v-progress-circular
        :size="150"
        color="yellow"
        indeterminate
        class="my-10"
      >
        {{ $t('loading') }}
      </v-progress-circular>
    </div>
    <v-data-iterator
      v-else
      :items="products"
      class="product-grid"
      :search="search"
      :options.sync="options"
      :items-per-page="itemsPerPage"
      :server-items-length="totalProducts"
      :footer-props="footerProps"
    >
      <template #header>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                v-model="options.sortBy"
                outlined
                class="border-shaped"
                :label="$t('sort')"
                :items="keys"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" class="mt-n8 mt-md-0">
              <v-btn-toggle v-model="options.sortDesc" mandatory>
                <v-btn x-large class="border-shaped" :value="false">
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn x-large class="border-shaped ml-2" :value="true">
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="options.etat"
                outlined
                class="border-shaped"
                :label="$t('product.status')"
                :items="etat_items"
                :item-text="(item) => $t('product_status.' + item.id)"
                item-value="id"
                clearable
                @change="getProducts"
              ></v-select
            ></v-col>
            <v-col cols="12" md="3" class="mt-n8 mt-md-0">
              <v-text-field
                v-model="search"
                outlined
                :label="$t('form.search')"
                prepend-inner-icon="mdi-magnify"
                class="border-shaped"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-container>
      </template>
      <template #default="props">
        <v-container>
          <v-row>
            <ProductForm></ProductForm>
            <v-col
              v-for="(product, i) in props.items"
              :key="i"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
              class="book"
            >
              <v-lazy class="fill-height">
                <product-detail :product="product"></product-detail>
              </v-lazy>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template #no-data>
        <v-row>
          <ProductForm ref="addProduct"></ProductForm>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-lazy transition="scale-transition">
              <v-card class="book add-book" shaped elevation="0">
                <v-card-text>
                  <v-container fill-height>
                    <v-row
                      justify="center"
                      align="center"
                      class="add-book-text grey--text"
                    >
                      <v-col cols="12" align="center">
                        <v-icon class="display-3 grey--text"
                          >mdi-emoticon-sad-outline</v-icon
                        >
                        <div>{{ $t('no_data') }}</div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-lazy>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </section>
</template>

<script lang="js">

import axios from 'axios'
import ProductDetail from '@/components/library/ProductDetail'
import ProductForm from '@/components/library/ProductForm'
import { EventBus } from '@/utils'

export default {
  name: 'ProductGrid',
  components: { ProductForm, ProductDetail },
  props: [],
  data () {
    return {
      options: {
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        sortDesc: true,
        sortBy: 'updated_at',
        etat: null,
        itemsPerPage: 17,
        page: 1,
        search: ''
      },
      itemsPerPage: 17,
      products: [],
      totalProducts: 0,
      etat_items: [],
      loading: true,
      search: '',
      intervalSearch: null
    }
  },
  computed: {
    keys: function () {
      return [
        { name: this.$t('date'), value: 'updated_at' },
        { name: this.$t('product.title'), value: 'title' },
        { name: this.$t('product.isbn'), value: 'isbn' },
        { name: this.$t('product.reference'), value: 'reference' },
        { name: this.$t('product.author'), value: 'author' }
      ]
    },
    footerProps: function () {
      return {
        'items-per-page-options': [5, 11, 17, 35, 53, 77, 101],
        itemsPerPageText: this.$t('items_per_page')
      }
    }
  },
  watch: {
    'options.sortDesc' () {
      this.getProducts()
    },
    'options.sortBy' () {
      this.getProducts()
    },
    'options.page' () {
      this.getProducts()
    },
    'options.itemsPerPage' () {
      this.getProducts()
    },
    search () {
      clearTimeout(this.intervalSearch)
      this.intervalSearch = setTimeout(() => {
        this.options.search = this.search
        this.getProducts()
      }, 750)
    }
  },
  mounted () {
    this.getProducts()
    this.getEtats()
    EventBus.$on('refreshProducts', () => {
      this.getProducts()
    })
  },
  methods: {
    getEtats () {
      axios
        .get(process.env.VUE_APP_API_URL + '/etat', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`
          }
        })
        .then(response => {
          this.etat_items = response.data.etats.slice(0, 3)
        })
    },
    getProducts () {
      this.loading = true
      axios
        .get(process.env.VUE_APP_API_URL + '/product', {
          params: this.options,
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
          }
        })
        .then(response => {
          this.products = response.data.products
          this.totalProducts = response.data.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.product-grid {
  width: 100%;
}

.book {
  min-height: 500px;
  max-height: 550px;

  .book-card {
    min-height: 100%;
  }

  .book-text {
    position: absolute;
    bottom: 0;
    background: var(--color-yellow);
    opacity: 0.85;
    height: 25%;
    transition: height 0.3s, opacity 0.3s;
  }

  .book-text-hover {
    height: 100%;
    opacity: 0.99;
    cursor: pointer;
  }

  .book-state {
    position: absolute;
    left: 5px;
    top: 5px;
    transition: all 0.3s;
  }

  .book-state-hover {
    left: 75%;
  }
}

.img-detail {
  height: 150px;
  transition: height 0.3s;
}

.img-detail-hover {
  height: 30em;
}

.add-book {
  .add-book-text {
    min-height: 443px;
  }

  border-style: dashed !important;
  border-width: 1px !important;
  border-color: rgb(192, 192, 192) !important;
}
</style>

<i18n lang="json">
{
  "fr": {
    "sort": "Trier par",
    "date": "Date",
    "items_per_page": "Produits par page",
    "no_data": "Aucun produit"
  },
  "en": {
    "sort": "Sort by",
    "date": "Date",
    "items_per_page": "Products per page",
    "no_data": "No data"
  }
}
</i18n>
