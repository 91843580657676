<template>
  <div>
    <v-btn class="yellow--bg border-shaped" depressed @click="toggleDialog">
      <v-icon>mdi-information</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="1100px">
      <v-sheet v-if="loading" class="text-center">
        <v-progress-circular
          :size="100"
          color="yellow"
          indeterminate
          class="my-10"
        ></v-progress-circular>
      </v-sheet>
      <v-card v-if="order" shaped elevation="24">
        <v-card-title class="text-h5 text-lg-h4 font-weight-bold">
          {{ order.uid }}
          <v-btn
            v-if="order.f1.id > 2"
            large
            class="ml-auto border-shaped yellow--bg"
            depressed
            :loading="loading_facture"
            @click="getFacture"
          >
            Facture Proforma
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="6">
                <div>
                  <strong
                    >Prix fabrication :
                    {{ order.prix_fabrication.toFixed(2) }} €</strong
                  >
                </div>
                <div>
                  <strong
                    >Prix emballage :
                    {{ order.prix_emballage.toFixed(2) }} €</strong
                  >
                </div>
                <div>
                  <strong
                    >Prix livraison :
                    {{ order.prix_livraison.toFixed(2) }} €</strong
                  >
                </div>
                <div>
                  <strong>Prix HT : {{ order.prix_ht.toFixed(2) }} €</strong>
                </div>
                <div>
                  <strong>Prix TTC : {{ order.prix_ttc.toFixed(2) }} €</strong>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div><strong>État</strong> : {{ order.f1.name }}</div>
                <div>
                  <strong>Crée le</strong> :
                  {{
                    new Date(
                      new Date(order.created_at).toLocaleString('en-US') +
                        ' UTC'
                    ).toLocaleString()
                  }}
                </div>
                <div>
                  <strong>Mis à jour le</strong> :
                  {{
                    new Date(
                      new Date(order.updated_at).toLocaleString('en-US') +
                        ' UTC'
                    ).toLocaleString()
                  }}
                </div>
                <div>
                  <strong>Utilisateur</strong> : {{ order.user.username }} -
                  {{ order.user.real_name }} - {{ order.user.email }}
                </div>
              </v-col>
            </v-row>
            <v-select
              v-model="f1"
              label="Etat"
              outlined
              class="border-shaped mt-5 mb-n5"
              return-object
              :items="etat_items"
              item-text="name"
              required
              :rules="etatRules"
              @change="updateEtat"
            ></v-select>
          </v-container>
          <v-data-table
            :headers="headers"
            :items="lignes"
            :items-per-page="5"
            :loading="loading"
            :footer-props="footerProps"
          >
            <template #[`item.product`]="{ item }">
              <v-list style="background: transparent">
                <v-list-item v-if="item.enabled">
                  <v-list-item-avatar
                    v-if="$vuetify.breakpoint.smAndUp"
                    rounded
                    size="100"
                  >
                    <VignetteFetcher :id="item.product.id" />
                  </v-list-item-avatar>

                  <v-list-item-content class="text-left">
                    <v-list-item-title>{{
                      item.product.title
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >{{ item.product.page_count }} pages</v-list-item-subtitle
                    >
                    <v-list-item-subtitle>
                      {{ item.address.address_full }}
                      {{ item.address.country.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <strong>Contact</strong> : {{ item.address.contact }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.address.raison_sociale">
                      <strong>Raison Sociale</strong> :
                      {{ item.address.raison_sociale }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
            <template #[`item.action`]="{ item }">
              <div class="d-flex">
                <lines-order-admin-edit :line="item"></lines-order-admin-edit>
                <v-btn
                  class="yellow--bg border-shaped ml-1"
                  depressed
                  v-if="item.url_suivi"
                  :href="item.url_suivi"
                  target="_blank"
                >
                  <v-icon>mdi-truck-fast-outline</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            class="border-shaped ml-auto"
            depressed
            @click="deleteOrder(order.id)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="js">

import axios from 'axios'
import LinesOrderAdminEdit from '@/components/admin/lignescommande/LinesOrderAdminEdit'
import { EventBus } from '@/utils'
import VignetteFetcher from '@/components/order/VignetteFetcher'

export default {
  name: 'OrdersAdminDetail',
  components: { LinesOrderAdminEdit, VignetteFetcher },
  props: ['id'],
  data () {
    return {
      order: null,
      dialog: null,
      loading: true,
      loading_facture: false,
      f1: null,
      etat_items: [],
      etatRules: [v => v !== null || 'Etat requis'],
      apiUrl: process.env.VUE_APP_API_URL,
      headers: [
        { text: 'ID', align: 'start', value: 'id' },
        { text: 'Produit', value: 'product' },
        { text: 'Quantité', value: 'quantity' },
        { text: 'État', value: 'f1.name' },
        { text: 'Commentaire', value: 'comment' },
        { text: 'Action', value: 'action' }
      ],
      footerProps: {
        'items-per-page-options': [5, 10, 15, 30, 50, 100, -1],
        itemsPerPageText: 'Lignes par page'
      }
    }
  },
  computed: {
    lignes: function () {
      try {
        return this.order.lignes_commande.filter(l => l.enabled === true)
      } catch (e) {
        return []
      }
    }
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        this.getOrder()
      }
    }
  },
  mounted () {
  },
  methods: {
    toggleDialog () {
      this.dialog = !this.dialog
      if (this.dialog && !this.opened) {
        this.getEtats()
        this.opened = true
      }
    },
    getEtats () {
      axios
        .get(process.env.VUE_APP_API_URL + '/etat/order', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`
          }
        })
        .then(response => {
          this.etat_items = response.data.etats
        })
    },
    updateEtat () {
      axios
        .put(
          process.env.VUE_APP_API_URL + '/admin/orders/' + this.order.id,
          {
            f1_id: this.f1.id
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then(() => {
          EventBus.$emit('refreshPanier')
          EventBus.$emit('refreshproducts')
          EventBus.$emit('refreshorders')
          this.loading = false
          EventBus.$emit('notif', 'Commande modifiée', 'success')
        })
        .catch(() => {
          EventBus.$emit('notif', 'Une erreur s\'est produite', 'error')
          this.loading = false
        })
    },
    getOrder () {
      axios
        .get(process.env.VUE_APP_API_URL + '/admin/orders', {
          params: {
            order_id: this.id
          },
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`
          }
        })
        .then(response => {
          this.order = response.data.commande
          this.f1 = this.order.f1
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    getFacture () {
      this.loading_facture = true
      axios
        .get(process.env.VUE_APP_API_URL + '/order/' + this.order.id + '/facture', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`
          },
          responseType: 'blob'
        })
        .then((response) => {
          this.loading_facture = false
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('target', '_blank') // or any other extension
          link.setAttribute('download', this.order.uid + '.pdf') // or any other extension
          link.setAttribute('href', url) // or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          this.loading_facture = false
          EventBus.$emit('notif', 'Impossible de générer la facture', 'error')
        })
    },
    deleteOrder (id) {
      if (confirm('Êtes-vous sûr de vouloir supprimer cette commande ?')) {
        axios
          .delete(process.env.VUE_APP_API_URL + '/admin/orders/' + id, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
          .then(() => {
            EventBus.$emit('refreshPanier')
            EventBus.$emit('refreshorders')
          })
          .catch(() => {
            EventBus.$emit('refreshPanier')
            EventBus.$emit('refreshorders')
          })
      }
    }
  }
}
</script>
