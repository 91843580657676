<template>
  <section class="contact">
    <page-title :title="$t('title')" :breadcrumbs="items"></page-title>
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-form ref="formContact" @submit.prevent="sendMessage">
            <v-text-field
              v-if="!user"
              v-model="username"
              label="Nom"
              class="border-shaped"
              :rules="notNullRules"
              outlined
            ></v-text-field>
            <v-text-field
              v-if="!user"
              v-model="email"
              label="Email"
              class="border-shaped"
              :rules="notNullRules"
              outlined
            ></v-text-field>
            <v-text-field
              v-if="!user"
              v-model="phone"
              label="Téléphone"
              class="border-shaped"
              :rules="notNullRules"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="objet"
              :label="$t('object')"
              class="border-shaped"
              :rules="notNullRules"
              outlined
              required
              :disabled="isInscription && !user"
            ></v-text-field>
            <v-textarea
              v-model="contenu"
              class="border-shaped"
              :label="
                isInscription && !user ? $t('description') : $t('content')
              "
              :rules="notNullRules"
              outlined
              required
            ></v-textarea>
            <v-btn
              class="yellow--bg border-shaped"
              large
              type="submit"
              elevation="0"
              :loading="loading"
            >
              {{ $t('submit-contact') }}
            </v-btn>
          </v-form>
        </v-col>
        <v-col cols="12" md="6">
          <h2 class="text-uppercase">{{ $t('our_address') }}</h2>
          <div>CORLET IMPRIMEUR</div>
          <div>ZI ROUTE DE VIRE</div>
          <div>14110 CONDE EN NORMANDIE</div>
          <div class="font-weight-bold">02.31.59.53.00</div>
          <v-switch
            v-if="!user"
            v-model="isInscription"
            :label="$t('register_demand')"
          ></v-switch>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script lang="js">

import PageTitle from '@/components/info/PageTitle'
import axios from 'axios'
import { EventBus } from '@/utils'

export default {
  name: 'Contact',
  metaInfo: {
    title: 'Rapidbook - Contact',
    metas: [
      {
        name: 'com.silverpop.brandeddomains',
        content: 'www.pages06.net,corlet.fr,rapidbook.fr,www.corlet.fr,www.rapidbook.fr'
      }
    ],
    script: [
      {
        src: 'https://www.sc.pages06.net/lp/static/js/iMAWebCookie.js?40625c3e-18bd96a8009-3c9d767ebba0be65b5fa68bf26a6ee93&h=www.pages06.net'
      }
    ]
  },
  components: { PageTitle },
  props: [],
  data () {
    return {
      user: null,
      username: '',
      email: '',
      phone: '',
      objet: '',
      contenu: '',
      isInscription: false,
      loading: false,
      notNullRules: [v => !!v || this.$t('form.required.required')],
      items: [
        {
          text: 'Rapidbook',
          disabled: false,
          to: '/'
        },
        {
          text: 'Dashboard',
          disabled: false,
          to: 'dashboard'
        },
        {
          text: this.$t('contact'),
          disabled: true,
          to: ''
        }
      ]
    }
  },
  computed: {},
  watch: {
    isInscription (newValue) {
      if (newValue) {
        this.objet = 'Demande d\'inscription'
      } else {
        this.objet = ''
      }
    }
  },
  mounted () {
    if (this.$store.getters.isAuthenticated) {
      this.getUser()
    }
  },
  methods: {
    sendMessage (e) {
      e.preventDefault()
      if (!this.$refs.formContact.validate()) {
        return
      }
      this.loading = true
      axios
        .post(
          process.env.VUE_APP_API_URL + '/contact',
          {
            name: this.username,
            email: this.email,
            telephone: this.phone,
            objet: this.objet,
            contenu: this.contenu
          },
          this.$store.getters.isAuthenticated
            ? {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.getJwtToken}`
                }
              }
            : {})
        .then(() => {
          EventBus.$emit('notif', this.$t('sended'), 'success')
          this.username = ''
          this.email = ''
          this.phone = ''
          this.objet = ''
          this.contenu = ''
          this.$refs.formContact.reset()
        })
        .catch(() => {
          EventBus.$emit('notif', this.$t('global.error'), 'error')
        })
        .finally(() => {
          this.loading = false
        })
    },
    getUser () {
      axios
        .get(process.env.VUE_APP_API_URL + '/user', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`
          }
        })
        .then(response => {
          this.user = response.data.user
          this.user.addresses.map(a => {
            if (a.pinned) {
              this.address = a
            }
          })

          this.username = this.user.real_name
          this.email = this.user.email
          this.phone = this.user.phone
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.contact {
  min-height: calc(100vh - 64px);
}
</style>

<i18n lang="json">
{
  "fr": {
    "title": "Nous Contacter",
    "object": "Objet",
    "email": "Email",
    "phone": "Téléphone",
    "description": "Description du projet et Informations supplémentaires",
    "content": "Contenu",
    "submit-contact": "Envoyer",
    "our_address": "Notre Adresse",
    "register_demand": "Demande d'inscription",
    "sended": "Mail envoyé"
  },
  "en": {
    "title": "Contact Us",
    "object": "Subject",
    "email": "Email",
    "phone": "Phone",
    "description": "Project Description and Additional Information",
    "content": "Content",
    "submit-contact": "Send",
    "our_address": "Our Address",
    "register_demand": "Registration Request",
    "sended": "Mail sent"
  }
}
</i18n>
