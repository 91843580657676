<template>
  <v-dialog v-model="dialog" max-width="900px">
    <template #activator="{ on, attrs }">
      <v-btn class="border-shaped mb-4" v-bind="attrs" text small v-on="on">
        {{ $t('forgot_password') }}
      </v-btn>
    </template>
    <v-form
      ref="formResetRequest"
      v-model="valid"
      @submit.prevent="sendResetRequest"
    >
      <v-card shaped elevation="24">
        <v-card-title class="text-h4 text-lg-h4 font-weight-bold">
          {{ $t('forgot_password') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            v-model="email"
            class="border-shaped"
            :label="$t('global.email')"
            outlined
            :rules="emailRules"
            type="email"
          ></v-text-field>
          <span class="text-caption">{{ $t('mail_will_be_sent') }}</span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="border-shaped yellow--bg ml-auto"
            type="submit"
            :loading="loading"
            large
            depressed
          >
            {{ $t('form.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from 'axios'

import isEmpty from 'validator/lib/isEmpty'
import isEmail from 'validator/lib/isEmail'

import { EventBus } from '@/utils'

export default {
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      email: '',
      emailRules: [
        (v) => !isEmpty(v) || this.$t('form.required.email'),
        (v) => isEmail(v) || this.$t('form.invalid.email'),
      ],
    }
  },
  methods: {
    sendResetRequest() {
      this.loading = true
      this.$refs.formResetRequest.validate()
      if (this.valid) {
        axios
          .post(process.env.VUE_APP_API_URL + '/reset_password', {
            email: this.email,
          })
          .then(() => {
            EventBus.$emit('notif', this.$t('mail_sent'), 'success')
            this.dialog = false
          })
          .catch(() => {
            EventBus.$emit('notif', this.$t('error'), 'error')
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>

<i18n lang="json">
{
  "fr": {
    "forgot_password": "Mot de passe oublié ?",
    "mail_will_be_sent": "Un email vous sera envoyé afin de réinitialiser votre mot de passe.",
    "mail_sent": "Un email vous a été envoyé.",
    "error": "Une erreur s'est produite. l'email ne correspond peut-être pas à un compte."
  },
  "en": {
    "forgot_password": "Forgot password ?",
    "mail_will_be_sent": "An email will be sent to reset your password.",
    "mail_sent": "An email has been sent.",
    "error": "An error has occurred. The email may not be associated with an account."
  }
}
</i18n>
